// $primary: #f7941d;
// $secondary: #ed1a3b;
// $info: #828282;
$breadcrumb-divider: ">";

// $theme-colors: (
//   "primary": $primary,
//   "secondary": $secondary,
//   "info": $info,
//   "black-gray": #313335,
// );
@import "node_modules/bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Estonia&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// @import "ui-neumorphism/dist/index.css";

// @import "slick-carousel/slick/slick.css";
// @import "slick-carousel/slick/slick-theme.css";

body {
  font-family: "Nunito" !important;
}

.nunito-200 {
  font-family: "Nunito";
  font-weight: 200;
  font-style: normal;
}

.nunito-200-i {
  font-family: "Nunito";
  font-weight: 200;
  font-style: italic;
}

.nunito-300 {
  font-family: "Nunito";
  font-weight: 300;
  font-style: normal;
}

.nunito-300-i {
  font-family: "Nunito";
  font-weight: 300;
  font-style: italic;
}

.nunito-400 {
  font-family: "Nunito";
  font-weight: 400;
  font-style: normal;
}

.nunito-400-i {
  font-family: "Nunito";
  font-weight: 400;
  font-style: italic;
}

.nunito-500 {
  font-family: "Nunito";
  font-weight: 500;
  font-style: normal;
}

.nunito-500-i {
  font-family: "Nunito";
  font-weight: 500;
  font-style: italic;
}

.nunito-600 {
  font-family: "Nunito";
  font-weight: 600;
  font-style: normal;
}

.nunito-600-i {
  font-family: "Nunito";
  font-weight: 600;
  font-style: italic;
}

.nunito-700 {
  font-family: "Nunito";
  font-weight: 700;
  font-style: normal;
}

.nunito-700-i {
  font-family: "Nunito";
  font-weight: 700;
  font-style: italic;
}

.nunito-800 {
  font-family: "Nunito";
  font-weight: 800;
  font-style: normal;
}

.nunito-800-i {
  font-family: "Nunito";
  font-weight: 800;
  font-style: italic;
}

.nunito-900 {
  font-family: "Nunito";
  font-weight: 900;
  font-style: normal;
}

.nunito-900-i {
  font-family: "Nunito";
  font-weight: 900;
  font-style: italic;
}

h1 {
  font-size: 96px;
}

h2,
.h2 {
  font-size: 64px;
}

h3 {
  font-size: 40px;
}

h4,
.h4 {
  font-size: 24px;
}

.subtitle {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
}

.body-text1 {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.body-text2 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.caption {
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.overline {
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 1.5px;
}

.small-title {
  font-weight: 500;
  font-size: 10.5px;
}

// focus outline
.outline-none:focus,
.outline-none:active {
  outline: none !important;
  box-shadow: none !important;
}

// Buttons

.hyrebook-button {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 14px 40px;
  border-radius: 4px;
}

.znanye-login-input {
}

.znanye-button-large {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 20px 118px;
  border-radius: 4px;
}

.znanye-icon-button {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 14px 14px;
  border-radius: 4px;
}

.explore-button {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.25px;
  padding: 14px 40px;
  border-radius: 4px;
  background: none;
  background-image: linear-gradient(93.69deg, #ed1a3b 7.74%, #511aed 100%);
}

.border-R20 {
  border-radius: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.text-shadow {
  text-shadow: -4px -4px 10px #555;
}

.hyrebook-maintenance {
  background: url("./hyrebook-maintenance.svg");
  background-size: cover;
}
